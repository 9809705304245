:root {
  --beUpColor: #e94e1b;
  --beUpColorLight: #ff7a3d;
  --titleTextColor: #000000;
  --textColor: #4b4d4c;
  --buttonMinWidth: 140px;
  --dialogMinWidth: 600px;
  --borderRadius: 6px;
  --excelColor: #107c41;
  --pdfColor: #e20001;
  --tableGridColor: #dee2e6;
  --tableHeaderBgColor: #f8f9fa;
  --expanderColumnWidth: 5rem;
  --tableCellPadding: 1rem;
}

body {
  background-color: #f0f1f4;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-style: normal;
  min-height: 100vh;
  width: 100%;
  color: var(--textColor);
}

h1,
h2 {
  color: var(--titleTextColor);
}

h1,
h2,
h3,
h4,
h5,
h6,
label {
  font-family: inherit;
  margin: 0;
  padding: 0;
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 15px;
}

h2 {
  font-size: 2.2em;
}

label {
  font-weight: 600;
}

span,
p,
input {
  margin: 0;
  padding: 0;
  color: inherit;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

hr {
  display: block;
  margin: 10px 0px;
  border: 0;
  height: 1px;
  background-color: var(--textColor);
}

small {
  display: block;
  padding: 5px;
}
*::-webkit-scrollbar {
  background-color: #f9f9fd;
  width: 10px;
  height: 10px;
}
*::-webkit-scrollbar-thumb {
  background-color: var(--beUpColorLight);
  border-radius: 6px;
}
*::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgb(0 0 0/8%);
}

.container_local {
  padding: 30px 45px;
}

.global_content_container {
  display: flex;
  height: 100%;
  max-height: 860px;
}

.icon {
  height: 23px;
  width: 23px;
  fill: var(--beUpColor);
  font-size: 22px;
}

.spacer {
  background: linear-gradient(270deg, #ffffff 0%, var(--beUpColor) 100%);
  border: none;
  margin: 0;
  height: 4px;
  width: 60px;
}

.page,
.tabView {
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 100%;
}

.page_content {
  height: 100%;
  padding-right: 10px;
  overflow-y: auto;
}

.tabView,
.tabView .p-tabview-panel {
  height: 100%;
}
.p-tabview-panels{
  overflow-x: auto !important;
  border-radius: 0 !important;
}
.align_self_center {
  align-self: center;
}

.text_align_center {
  text-align: center !important;
}

.logo {
  width: 150px;
}

.short_logo {
  width: 50px;
}

.beUpColor {
  color: var(--beUpColor);
}

.excel_button {
  color: var(--excelColor) !important;
}

.pdf_button {
  color: var(--pdfColor) !important;
}

.ps-menu-button {
  transition: background-color 0.1s ease-in-out !important;
}

.ps-menu-button:hover,
.ps-menu-button.ps-active {
  background-color: var(--beUpColorLight) !important;
  color: #ffffff !important;
}

.ps-menu-button:hover .icon,
.ps-menu-button.ps-active .icon {
  fill: #ffffff;
}

.ps-menu-button.ps-open,
.ps-menu-button.ps-active {
  font-weight: 500 !important;
}

.p-password-input {
  width: 100%;
}

.info_component_grid,
.form_fields_grid {
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 25px;
  column-gap: 15px;
}

.form_input_container {
  display: flex;
  flex-direction: column;
}

.submit_button {
  max-width: var(--buttonMinWidth);
}

.dialog {
  min-width: var(--dialogMinWidth);
}

.dialog_password_field input {
  width: 100%;
}

.table_container {
  height: 100%;
  position: relative;
}

.table {
  width: 100%;
  height: 100%;
}

.subTable {
  width: 60%;
  height: 100%;
  margin-left: calc(var(--expanderColumnWidth) - var(--tableCellPadding));
}

.table > .p-datatable-header {
  min-height: 12%;
}

.table > .p-datatable-wrapper {
  min-height: 90%;
}

.table.with_header > .p-datatable-wrapper {
  min-height: 78%;
}

.table > .p-paginator {
  min-height: 10%;
}

.expander_column {
  width: var(--expanderColumnWidth);
}

.table_header_toolBar {
  padding: 0;
  border: 0;
}

.padding0 {
  padding: 0 !important;
}

.select_option {
  margin: 0;
  padding: 0.75rem 1.25rem;
  border: 0 none;
  color: #000;
  background: rgba(0, 0, 0, 0);
  transition: box-shadow 0.2s;
  border-radius: 0;
  cursor: pointer;
  font-weight: normal;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 2000;
}

.switches_grid{
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 42px;
  row-gap: 25px;
  column-gap: 15px;
  justify-content: center;
}

.justifyContentSpaceBetween{
  justify-content: space-between;
}

@media (max-width: 1024px) {
  .container_local {
    padding: 30px;
  }
}

@media (max-width: 768px) {
  :root {
    --dialogMinWidth: 220px;
  }
  .dialog{
    max-height: 100%;
  }

  .subTable {
    width: 100%;
    height: 100%;
    margin-left: 0;
  }
}

@media (max-width: 425px) {
  html {
    font-size: 12px;
  }

  h2 {
    font-size: 2em;
  }

  *::-webkit-scrollbar {
    background-color: #f9f9fd;
    width: 5px;
    height: 5px;
  }

  .logo {
    width: 120px;
  }
  .container_local {
    padding: 30px 15px;
  }

  .form_fields_grid {
    row-gap: 10px;
    grid-template-columns: 1fr;
    padding: 0px;
  }

  .info_component_grid{
    padding: 0px;
  }

  .dialog{
    width: 100%;
  }
}
