.languageSwitcher_option {
  display: flex;
  align-items: center;
}

.languageSwitcher_option_title {
  margin-left: 10px;
}

@media (max-width: 1024px) {
  .language_switcher > .p-dropdown-trigger,
  .languageSwitcher_option_title {
    display: none;
  }
}
