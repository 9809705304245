.NewVacationRequestForm_title {
  margin-bottom: 20px;
}

.NewVacationRequestForm_input_plannedVacationCalendar input {
  text-align: center;
}

.NewVacationRequestForm_input_vacationDaysNumber {
  max-width: 50px;
}
