.AdvancedFileUpload_header{
    justify-content: space-between;
}

.AdvancedFileUpload_emptyTemplate{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AdvancedFileUpload_emptyTemplate i{
    font-size: 5em;
    background-color: var(--surface-b);
    color: var(--surface-d);
}

.AdvancedFileUpload_emptyTemplate h3{
    color: var(--textColor) ;
}